import "./terms-and-conditions.css";
import "../components/BackgroundMain.css";

export const TermsAndConditions = () => {
  return (
    <div className="full-screen-div">
      <div className="terms-container">
        <h1 className="terms-title">Terms and Conditions</h1>
        <p className="terms-content">
          <strong>Effective Date:</strong> 2/20/2025
        </p>

        <h2 className="terms-section-title">1. Message Types</h2>
        <p className="terms-content">
          Users may receive service-related messages, including:
        </p>
        <ul className="terms-list">
          <li>Promotional offers</li>
          <li>Informational updates</li>
          <li>Appointment confirmations</li>
          <li>Service updates and responses to inquiries</li>
        </ul>

        <h2 className="terms-section-title">2. Message Frequency</h2>
        <p className="terms-content">
          Users may receive messages periodically, typically no more than 2-4
          times per month.
        </p>

        <h2 className="terms-section-title">3. Message and Data Rates</h2>
        <p className="terms-content">
          Standard message and data rates may apply. Please check with your
          mobile service provider.
        </p>

        <h2 className="terms-section-title">
          4. Unsubscribe & Opt-Out Instructions
        </h2>
        <ul className="terms-list">
          <li>
            To unsubscribe from SMS notifications, reply <strong>STOP</strong>.
          </li>
          <li>To unsubscribe from emails, click the "unsubscribe" link.</li>
          <li>
            For assistance, text <strong>HELP</strong> or contact{" "}
            <a href="mailto:customerservice@aimonsite.com">
              customerservice@aimonsite.com
            </a>
            .
          </li>
        </ul>

        <h2 className="terms-section-title">5. Sender Identification</h2>
        <p className="terms-content">
          Messages will be sent from <strong>AIM Blind & Drapery</strong>. By
          submitting an inquiry, you consent to receive messages from our
          company.
        </p>

        <h2 className="terms-section-title">6. Privacy Policy Link</h2>
        <p className="terms-content">
          For more details, please review our{" "}
          <a href="/privacy-policy">Privacy Policy</a>.
        </p>

        <h2 className="terms-section-title">7. Liability Disclaimer</h2>
        <p className="terms-content">
          Aim Blind and Drapery is not liable for damages resulting from website
          usage. We make efforts to keep information accurate, but do not
          guarantee it is error-free.
        </p>

        <h2 className="terms-section-title">8. Changes to These Terms</h2>
        <p className="terms-content">
          We reserve the right to update these Terms and Conditions at any time.
          Changes will be posted with an updated <strong>Effective Date</strong>
          .
        </p>

        <h2 className="terms-section-title">9. Contact Information</h2>
        <p className="terms-content">
          <strong>Aim Blind and Drapery</strong>
          <br />
          Phone: (650) 862-5588
          <br />
          Email:{" "}
          <a href="mailto:customerservice@aimonsite.com">
            customerservice@aimonsite.com
          </a>
        </p>

        <p className="terms-content">
          By using our website, you acknowledge that you have read, understood,
          and agreed to these Terms and Conditions.
        </p>
      </div>
    </div>
  );
};
