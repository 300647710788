import "./privacy-policy.css";
import "../components/BackgroundMain.css";

export const PrivacyPolicy = () => {
  return (
    <div className="full-screen-div">
      <div className="privacy-container">
        <h1 className="privacy-title">Privacy Policy</h1>
        <p className="privacy-effective-date">
          <strong>Effective Date:</strong> 3/9/2025
        </p>

        <p className="privacy-content">
          Aim Blind and Drapery respects your privacy. By opting into our SMS
          messaging service, you agree to the following terms regarding how we
          handle your data:
        </p>

        <h2 className="privacy-subtitle">Data Collection</h2>
        <p className="privacy-content">
          We will collect your name, email address, mailing address, and mobile
          phone number when you sign up for SMS updates. We collect opt-in
          verbally from our customers.
        </p>

        <h2 className="privacy-subtitle">Data Usage</h2>
        <p className="privacy-content">
          We use your data solely for scheduling, sending photos of blinds,
          answering customer questions, and technical support.
        </p>

        <h2 className="privacy-subtitle">Data Security</h2>
        <p className="privacy-content">
          We protect your data with secure storage measures to prevent
          unauthorized access.
        </p>

        <h2 className="privacy-subtitle">Data Retention</h2>
        <p className="privacy-content">
          We retain your information as long as you are subscribed to our SMS
          service. You may request deletion at any time.
        </p>

        <p className="privacy-content">
          MESSAGE AND DATA RATES MAY APPLY. Your mobile carrier may charge fees
          for sending or receiving text messages, especially if you do not have
          an unlimited texting or data plan.
        </p>

        <p className="privacy-content">
          Messages are recurring, and message frequency varies.
        </p>

        <h2 className="privacy-subtitle">Opt-Out</h2>
        <p className="privacy-content">
          You can opt out of the SMS list at anytime by texting, emailing or
          replying STOP or UNSUBSCRIBE to customerservice@aimonsite.com or (650)
          862-5588. After unsubscribing, you will receive a final SMS to confirm
          you have unsubscribed and we will remove your number from our list
          within 24 hours.
        </p>

        <p className="privacy-content">
          You can send HELP for additional additional assistance, and you will
          receive a text including our Phone number, email and website. We are
          here to help you.
        </p>

        <h2 className="privacy-subtitle">Non-Sharing Clause</h2>
        <p className="privacy-content">
          We do not share your data with third parties for marketing purposes.
          Aim Blind and Drapery will not sell, rent, or share the collected
          mobile numbers.
        </p>

        <h2 className="privacy-subtitle">How to Contact Us</h2>
        <p className="privacy-content">
          <strong>Aim Blind and Drapery</strong>
          <br />
          Phone: (650) 862-5588
          <br />
          Email:{" "}
          <a
            href="mailto:customerservice@aimonsite.com"
            className="privacy-link"
          >
            customerservice@aimonsite.com
          </a>
        </p>

        <p className="privacy-agreement">
          By using our website and submitting inquiries, you agree to the terms
          outlined in this Privacy Policy.
        </p>
      </div>
    </div>
  );
};
